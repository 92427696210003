@tailwind base;
@tailwind components;
@tailwind utilities;

.grecaptcha-badge {
  visibility: hidden;
}

@layer base {
  html {
    @apply tracking-wider inset-x-full;
  }

  h1 {
    @apply font-bold text-primaryLight dark:text-primaryDark text-4xl largeMobile:text-6xl tablet:text-[82px] xlarge:text-9xl;
  }

  h2 {
    @apply text-darkText text-xl;
  }
}

@layer components {
  .shadowBoxes {
    @apply border-primaryLight dark:border-primaryDark hover:shadow-lg hover:shadow-secondaryLight dark:hover:shadow-primaryDark transition-shadow ease-in-out duration-300 shadow-none;
  }

  .modalSubHeaders {
    @apply font-bold text-3xl largeMobile:text-5xl text-primaryLight dark:text-primaryDark;
  }

  .aboutSkills {
    @apply text-[10px] tablet:text-base font-semibold;
  }

  .largeBoxArticles {
    @apply flex flex-row items-center gap-2;
  }

  .aboutSkillBoxes {
    @apply flex flex-col items-center w-full justify-center space-y-5 text-darkText dark:text-lightText;
  }
}
